@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$btn-border-radius:           0;
$btn-border-radius-sm:        0;
$btn-border-radius-lg:        0;
// Colours
$primary: #5CC84B;
$light:   #FFF;
$dark:    #2A2828;
$darker:  #161616;
$success:  #368729;
$yellow:  #f59e0b;
$green:   #10b981;
$red:     #ef4444;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "darker":     $darker,
  "yellow":     $yellow,
  "green":      $green,
  "red":        $red
);

// Spacing
$spacer: 0.625rem;

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

// Typography
$subheading-font-size: 1.125rem;

// Nav
$navbar-brand-font-size:            $subheading-font-size;
$navbar-toggler-font-size:          $subheading-font-size;
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $primary;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        rgba($white, .25);
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-dark-toggler-border-color:  rgba($white, 0);
$navbar-dark-brand-color:           $navbar-dark-active-color;
$navbar-dark-brand-hover-color:     $navbar-dark-hover-color;

// Off Canvas
$offcanvas-border-color: $primary;
$offcanvas-border-width: 2px;
 
$btn-close-opacity: 1;