@import "variables";
@import "~bootstrap/scss/bootstrap";

// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';

@import '../assets/fontawesome-pro-6.1.1-web/scss/fontawesome';
@import '../assets/fontawesome-pro-6.1.1-web/scss/regular';
@import '../assets/fontawesome-pro-6.1.1-web/scss/solid';
@import '../assets/fontawesome-pro-6.1.1-web/scss/brands';
@import 'jquery-confirm/dist/jquery-confirm.min.css';

.multiselect__tags {
    border-radius: 0px;
}
.multiselect__tag {
    background: #00CB33;
}

[data-vuetify] {
    @import 'vuetify/dist/vuetify.min';
}

.footer-ukri {
    height: 4rem !important;
    display: inline !important;
}

.form-input {
    position: relative;
}

.form-input.offset > label {
    transform: translateY(-50%);
}

.form-input label.text-secondary {
    color: var(--bs-white)!important;
    font-size: 14px;
}

.form-input > label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
}

.form-input textarea {
    width: 100%;
    border: solid var(--bs-white) 2px;
    background-color: var(--bs-darker);
    color: var(--bs-white);
    border-radius: 0;
}

.form-input textarea:focus {
    outline: none !important;
    border-color: #00CB33;
    box-shadow: 0 0 10px #00CB33;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px var(--bs-darker) inset !important;
}

input:focus,
input:focus-visible,
select:focus,
select:focus-visible {
    outline: var(--bs-primary) solid 2px;
}

.checkbox-container {
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.625rem;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--bs-darker);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.25rem!important;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em white;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"][title="White"]::before,
input[type="checkbox"][title="Yellow"]::before,
input[type="checkbox"][title="Green"]::before,
input[type="checkbox"][title="Silver"]::before {
    box-shadow: inset 1em 1em black;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.icon-grid {
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.icon-grid i {
    justify-self: center;
    align-self: center;
}

.nav-tabs {
    border-bottom: none!important;
}

.nav-tabs .nav-link {
    background-color: var(--bs-dark)!important;
}

.nav-tabs .nav-link:hover {
    color: var(--bs-primary)!important;
    background-color: transparent!important;
}

.nav-tabs .nav-link.active {
    position: relative;
    background-color: transparent!important;
    color: var(--bs-primary)!important;
}

.nav-tabs .nav-link.active::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--bs-darker);
    height: 2px;
    width: 100%;
    transform: translateY(-1px);
}

.navbar-toggler.collapsed::after {
    display: none!important;
}

@media screen and (max-width:991px) {
    .tabs-navbar {
        background-color: var(--bs-dark);
    }

    .tabs-navbar ul {
        flex-direction: column;
    }

    .tabs-navbar a {
        border: none!important;
    }

    .tabs-navbar .nav-link.active::after {
        display: none;
    }

    form.border-white {
        border: none!important;
    }
}

#app .tox * {
    color: var(--bs-white)!important;
    background-color: var(--bs-darker)!important;
}

#app .tox .tox-tbtn svg {
    fill: var(--bs-white)!important;
}

#app .tox-toolbar__group:last-child {
    border-right: var(--bs-white) 1px solid;
}

#app .tox-toolbar-overlord {
    border-bottom: var(--bs-white) 1px solid;
}

#app .tox .tox-statusbar {
    background-color: var(--bs-darker);
    color: var(--bs-white);
}

#app .tox .tox-statusbar * {
    color: var(--bs-white);
}

#app .tox .tox-edit-area__iframe span {
    color: var(--bs-white!important);
    background-color: none!important;
}

table.table-bordered > * > * > * {
    border: 2px solid var(--bs-white);
}

// table .text-break > * {
//     border: none;
// }

table .text-break > *,
table.support-table tbody tr:last-child,
table.support-table tbody tr:last-child > * {
    border: none;
}

.artwork {
    cursor: pointer;
}

.artwork div.position-absolute {
    display: none;
}

.artwork:hover div.position-absolute {
    display: block;
}

.colour-grid {
    display: grid;
    grid-template-columns: repeat(7, min-content);
    gap: 10px;
}

.btn-check:checked + .btn {
    background-color: $success !important;
    color: white;
  }

.required-asterisk {
font-size: 1.2em !important;
color: red !important;
}